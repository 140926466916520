$black-colour: rgba(3, 3, 3, 1);
$lightGray-colour: rgba(228, 228, 228, 1);
$gray-colour: rgba(107, 118, 136, 1);
$brown-colour: rgba(70, 54, 74, 1);
$white-colour: rgba(255, 255, 255, 1);


/* LIGHT. */
$primary-colour: rgba(237, 242, 248, 1);
$background-colour: rgba(237, 242, 248, 1);
$navbar-colour: rgba(237, 242, 248, 1);
$secondary-colour: rgba(49, 59, 172, 1);

/* DARK. */
$primary-colour-dark: rgba(66, 65, 65, 1);
$background-colour-dark: rgba(66, 65, 65, 1);
$navbar-colour-dark: rgba(137, 137, 137, 0.602);
$secondary-colour-dark: rgba(49, 172, 74, 1);