@import './keyframes.scss';

.anim-bouncy {
    animation: bouncy 5s infinite linear;
    position: relative;
}

.stop-animation {
    -webkit-animation: none;
    -moz-animation: none;
    -o-animation: none;
    animation: none;
}