@import '../../../res/colours.scss';

.card {
    width: 22rem;
    height: 16.9rem;
    // display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: black; //$primary-colour;
    border-radius: 10px;
    overflow: hidden;
    // margin: 10px;

    border: 3px solid transparent;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    transition: 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
        border: 3px solid $secondary-colour;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    }

    &-img {
        width: 100%;
        height: 100%;

        img {
            display: block;
            margin: 0 auto;

            object-fit: cover;
            object-position: bottom center;
            width: 100%;
            height: 100%;
            transition: 0.3s ease-in;

            // filter: grayscale(75%);

            &:hover {
                transform: scale(1.2);
                // filter: grayscale(0%);
            }
        }
    }

    &-title {
        flex: 1;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 35px;
        padding: 5px 10px;
        text-align: center;
        font-size: 16px;
        text-overflow: ellipsis;
        // border-radius: 5px;
        background: rgba(186, 186, 186, 0.8);
        justify-content: center;

        z-index: 1;
        color: $black-colour;
    }

    // &-description {
    //     // background: #ececec;
    //     height: 35%;
    //     width: 100%;
    //     overflow-y: scroll;
    //     // margin: 10px;
    //     margin: 10px auto;
    //     padding-left: 10px;
    //     padding-right: 10px;
    //     // background: red;
    // }

    &-footer {
        flex: 1;
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 35px;
        justify-content: space-between;
        align-items: center;

        padding: 5px 10px 5px 10px; // top, right, bottom, left
        background: rgba(186, 186, 186, 0.8);

        z-index: 1;


        &-techs {
            flex: 1;
            display: flex;
            height: 100%;
            justify-content: flex-start;
            align-items: center;
            // background: green;

            svg {
                width: 20px;
                height: 80%;

                margin-right: 8px;
            }
        }

        &-links {
            flex: 1;
            display: flex;
            height: 100%;
            justify-content: flex-end;
            align-items: center;
            // background: red;

            a {
                color: $black-colour;
                // margin-right: 8px;

                transition: 0.3s ease-out;


                // &:hover {
                //     // transform: scale(1.3);
                //     // transform-origin: center;
                //     color: $secondary-colour;
                //     box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
                // }

                svg {
                    width: 20px;
                    height: 80%;

                    margin-left: 8px;
                }
            }
        }
    }
}


#dark {
    .card {
        background: white; //$primary-colour-dark;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

        &:hover {
            border: 3px solid $secondary-colour-dark;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
        }

        &-img {

            img {
                filter: grayscale(75%);

                &:hover {
                    filter: grayscale(0%);
                }
            }
        }

        &-title {
            background: rgba(0, 0, 0, 0.7);
            color: $white-colour;
        }

        /*&--description {
        
    }*/

        &-footer {
            background: rgba(0, 0, 0, 0.7);

            &-techs {

                svg {
                    color: $white-colour;
                }
            }

            &-links {

                a {
                    color: $white-colour;

                    &:hover {
                        color: $secondary-colour-dark;
                        // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }
    }
}