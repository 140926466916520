@import '../../res/colours.scss';
@import '../../utils/Animations/animations.scss';

.app__landing {
    display: flex;
    width: 100%;
    min-height: 100vh;

    justify-content: center;
    align-items: flex-start;
    // cursor: none;
    background: $primary-colour;
    z-index: 3;


    /* top | right | bottom | left */
    // padding: 0rem 0rem 0rem 5rem;

    &--hi {
        font-size: 1.2rem;


        transform-origin: center;
        animation: slide-b-fade-In .3s ease-out;
        animation-fill-mode: both;
        animation-delay: 0.35s;
    }

    &--myName {
        font-size: clamp(40px, 8vw, 80px);
        text-align: left;

        transform-origin: center;
        animation: slide-b-fade-In .3s ease-out;
        animation-fill-mode: both;
        animation-delay: 0.55s;
    }

    &--badge {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;


        transform-origin: center;
        animation: slide-b-fade-In .3s ease-out;
        animation-fill-mode: both;
        animation-delay: 0.75s;


        .badge-cmp,
        .tag-cmp {
            padding: 1rem 2rem;
            background: $white-colour;
            border-radius: 15px;
            flex-direction: row;
            width: auto;

            // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        }

        .tag-cmp {
            @media screen and (max-width: 550px) {
                padding: .4rem .8rem;
                margin: .2rem .1rem;
                // margin-top: 0.8rem;
            }
            flex-direction: column;
            // margin-top: 0.5rem;
            // border-radius: 0px;
            padding: .5rem 1rem;
            margin: 1rem .2rem;

            // transition: 0.3s ease-in-out;

            p {
                color: $black-colour;
                text-transform: uppercase;
                text-align: center;
            }

            &:hover {
                // cursor: pointer;
                // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);

                // margin: 1rem .5rem;
                // transform: scale(1.1);
                // transform-origin: center;

                // p {
                //     color: $secondary-colour;
                // }
            }
        }


        // Extra large or Desktop devices
        @media screen and (min-width: 2000px) {}

        // Mobile and tablet
        @media screen and (max-width: 1200px) {
            justify-content: flex-start;
            align-items: flex-start;
        }

        // Smaller mobiles
        @media screen and (max-width: 450px) {}
    }

    &--scroll-arrow {
        position: absolute;
        bottom: 0px;

        // @include animation(fade-in);
        // animation-duration: 0.3s;
        // animation-delay: 3s;
        // transition-timing-function: ease-out;

        // animation: fade-in 1s 4s ease-out;
        // animation-fill-mode: both;


        svg {
            color: $secondary-colour;
            width: 20px;
            height: 20px;
        }
    }
}


#dark {
    .app__landing {
        background: $primary-colour-dark;

        div {
            color: $white-colour;
        }

        &--badge {

            .badge-cmp,
            .tag-cmp {
                background: $black-colour;
                color: $white-colour;
            }
        }

        &--scroll-arrow {
    
            svg {
                color: $secondary-colour-dark;
            }
        }
    }
}


.anim--bounce-arrow {
    -moz-animation: fade-in 1s 4s, arrow-bounce 2s 6s infinite;
    -webkit-animation: fade-in 1s 4s, arrow-bounce 2s 6s infinite;
    animation: fade-in 1s 4s, arrow-bounce 2s 6s infinite;


    // -moz-animation: arrow-bounce 2s infinite;
    // -webkit-animation: arrow-bounce 2s infinite;
    // animation: arrow-bounce 2s infinite;
}

@include keyframes(arrow-bounce) {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
}