@import '../mixin.scss';

// @keyframes scale-down-bounce {
@include keyframes(scale-down-bounce) {
    0%      { transform: scale(1); }
    80%     { transform: scale(-0.10); }
    100%    { transform: scale(0); }
}

// @keyframes scale-up-bounce {
@include keyframes(scale-up-bounce) {
    0%      { transform: scale(0); }
    80%     { transform: scale(1.10); }
    100%    { transform: scale(1); }
}

// @keyframes bounce {
@include keyframes(bounce) {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    // 0%      { transform: translateY(0); }
    // 20%     { transform: translateY(0); }
    40%     { transform: translateY(-10px); }
    // 50%     { transform: translateY(0); }
    60%     { transform: translateY(-5px); }
    // 80%     { transform: translateY(0); }
    // 100%    { transform: translateY(0); }
}

@include keyframes(scale-fade-in) {
    0%      { opacity: 0; scale: 0; }
    100%    { opacity: 1; scale: 1; }
}

// @keyframes slide-left {
@include keyframes(slide-left) {
    0%      { transform: translate(500px, 0); }
    100%    { transform: translate(0, 0); }
}

// @keyframes slide-right {
@include keyframes(slide-right) {
    0%      { transform: translate(0, 0); }
    100%    { transform: translate(500px, 0); }
}

// @keyframes slide-up {
@include keyframes(slide-up) {
    0%      { transform: translate(0, 100px); }
    100%    { transform: translate(0, 0); }
}

// @keyframes slide-down {
@include keyframes(slide-down) {
    0%      { transform: translate(0, 0); }
    100%    { transform: translate(0, 100px); }
}

// @keyframes fade-in {
@include keyframes(fade-in) {
    0%      { opacity: 0; }
    100%    { opacity: 1; }
}

// @keyframes fade-out {
@include keyframes(fade-out) {
    0%      { opacity: 1; }
    100%    { opacity: 0; }
}



// @keyframes slide-l-fade-In {
@include keyframes(slide-l-fade-In) {
    0% {
        opacity: 0;
        transform: translate(200px, 0);
    }
    80% {
        opacity: 0.2;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

// @keyframes slide-r-fade-In {
@include keyframes(slide-r-fade-In) {
    0% {
        opacity: 0;
        transform: translate(-200px, 0);
    }
    80% {
        opacity: 0.2;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

// @keyframes slide-b-fade-In {
@include keyframes(slide-b-fade-In) {
    0% {
        opacity: 0;
        transform: translate(0, 200px);
    }
    80% {
        opacity: 0.2;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

// @keyframes slide-t-fade-In {
@include keyframes(slide-t-fade-In) {
    0% {
        opacity: 0;
        transform: translate(0, -200px);
    }
    80% {
        opacity: 0.2;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

// @keyframes scale-bounce {
@include keyframes(scale-bounce) {
    0% {
        transform: scale(0);
    }
    80% {
        transform: scale(1.10);
    }
    100% {
        transform: scale(1);
    }
}

// @keyframes ripple {
@include keyframes(ripple) {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
        opacity: 1;
    }
    100% {
        transform: scale(1.6);
        opacity: 0;
    }
}

// @keyframes wiggle {
@include keyframes(wiggle) {
    0%, 100% {
        transform: translate(2px, 0);
    }
    // 0% { transform: translate(2px, 0); }
    50% { transform: translate(-2px, 0); }
    // 100% { transform: translate(2px, 0); }
}

// @keyframes bouncy {
@include keyframes(bouncy) {
    0%, 40%, 46%, 50%, 100% {
        top: 0em;
    }
    // 0% { top: 0em }
    // 40% { top: 0em }
    43% { top: -0.5em }
    // 46% { top: 0em }
    48% { top: -0.2em }
    // 50% { top: 0em }
    // 100% { top: 0em; }
}