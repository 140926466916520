@import '../../res/colours.scss';

$length: 9;

.app__skills {
    flex: 1;
    display: flex;
    width: 100%;
    min-height: 50vh;
    justify-content: flex-start;
    align-items: flex-start;
    background: $primary-colour;
    z-index: 3;


    &--title {

        // span {
            font-size: 30px;
        // }
    }

    &--text {
        font-size: 18px;
        padding-bottom: 10px;
    }

    .skill__cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        // background: green;


        .skill__card--item {
            @media screen and (max-width: 900px) {
                width: 60px;
            }
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: center;
            width: 70px;
            border-radius: 10px;
            overflow: hidden;
            margin: 5px;
            padding: 5px;
            
            border: 3px solid transparent;

            transition: 0.3s ease-in-out;

            img {
                width: 100%;
                // height: 100%;
            }

            &-text {
                margin-top: 5px;
                @media screen and (max-width: 900px) {
                    font-size: 11px;
                }
                font-size: 13px;
                font-weight: bold;
            }

            // &:hover {
            //     border: 0.5px solid $secondary-colour;
            // }
        }


        // @for $i from 1 through $length {
        //     &:nth-child(#{$length}n+#{$i}) {
        //         .skill__card--item {
        //             // transform: translateY(-);
        //         }
        //     }
        // }
    }
}


#dark {

    .app__skills {
        background: $primary-colour-dark;

        .skill__cards {
            .skill__card--item {
    
                // &:hover {
                //     border: 3px solid $secondary-colour-dark;
                //     box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.5);
                // }
            }
        }
    }
}