@import '../../utils/Animations/animations.scss';
@import '../../res/colours.scss';

.app__joke {
    @media screen and (max-width: 500px) {
        padding: 0 2rem;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    background: $primary-colour;
    z-index: 3;



    .touch-ripple {
        @media screen and (min-width: 900px) {
            width: 40px;
            height: 40px;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        position: absolute;
        // transform: translate(-50%, -50%);
        margin-top: 180px;
        border-radius: 50%;
        


        transform-origin: center;
        // @include animation(slide-b-fade-In);
        animation: slide-b-fade-In .8s 0.9s ease-out;
        animation-fill-mode: both;


        > p {
            text-align: center;
            // vertical-align: middle;
            // line-height: 100px;
            margin: 0;
            font-size: 30px;

            // transform-origin: bottom;
            transition: 0.3s ease-in-out;


            // @include wiggle
            animation: wiggle-hand 1s 1.1s infinite;
            animation-timing-function: linear;
            transform-origin: bottom center;
        }

        &:hover {
            cursor: pointer;

            // > p {
            //     // @include wiggle
            //     animation: wiggle-hand 1s infinite;
            //     animation-timing-function: linear;
            //     transform-origin: bottom center;
            // }
        }


        // &:before,
        // &:after {
        //     content: '';
        //     display: block;
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     left: 0;
        //     bottom: 0;
        //     border: 1px solid $secondary-colour;
        //     border-radius: 50%;
        // }
    }

    .touch-ripple:before,
    .touch-ripple:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border: 1px solid $secondary-colour;
        border-radius: 50%;
    }

    .touch-ripple:before {
        animation: ripple 1s ease-out infinite;
    }
    .touch-ripple:after {
        animation: ripple 2s ease-out infinite;
    }
}


#dark {

    .app__joke {
        background: $primary-colour-dark;

        .touch-ripple:before,
        .touch-ripple:after {
            content: '';
            border: 1px solid $secondary-colour-dark;
        }
    }
}


@include keyframes(wiggle-hand) {
    0%, 80%, 100% {
        transform: rotate(0deg);
    }
    // 0% { transform: rotate(0deg); }
    20% { transform: rotate(-10deg); }
    60% { transform: rotate(10deg); }
    // 80% { transform: rotate(0deg); }
    // 100% { transform: rotate(0deg); }
}