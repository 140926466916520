@import '../../../res/colours.scss';
@import '../../../utils/Animations/animations.scss';


.contact__modal {
    display: inline-block;
    width: 100%;
    height: 100%;


    &--content {
        @media screen and (max-width: 900px) {
            // width: 350px;
        }
        grid-area: box;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // width: 400px;
        width: 100%;
        background-color: $white-colour;
        padding: 40px;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, .1);
        /* box-shadow: 0 30px 20px rgba(0,0,0,.2); */
        box-shadow: 0 3px 60px rgba(57, 73, 76, 0.35);



        header {
            @media screen and (max-width: 900px) {
                font-size: 24px;
            }
            font-size: 30px;
            text-align: center;
            padding-bottom: 30px;
            font-weight: bold;
        }


        /*form {

            input[type="submit"] {
                background: $secondary-colour;
                color: #FFF;
                font-size: 1.1em;
                border-bottom: none;
                cursor: pointer;
                margin-bottom: 0;
                text-transform: uppercase;

                border-radius: 10px;

                &:hover {
                    // background: #37a000;
                }
            }
        }*/

        .submited-close-button {
            background: $secondary-colour;
            color: #FFF;
            font-size: 1.1em;
            border-bottom: none;
            cursor: pointer;
            margin-bottom: 0;
            text-transform: uppercase;

            border-radius: 10px;

            width: 100%; //50%;
            // box-sizing: border-box;
            box-shadow: none;
            outline: none;
            border: none;
            border-bottom: 2px solid #999;
            padding: 10px 0;
            margin-top: 20px;

            // transition: var(--modeTransition);
            transition: 0.3s ease-in-out;

            &:active {
                box-shadow: 0 0.5em 0.5em -0.4em $secondary-colour;
                transform: translateY(-0.25em);
            }
        }
    }
}

.close-button {
    background: $secondary-colour;
    color: #FFF;
    transition: 0.3s ease-in-out;

    &:active {
        box-shadow: 0 0.5em 0.5em -0.4em $secondary-colour;
        transform: translateY(-0.25em);
    }
}

#dark {

    .contact__modal {
    
        &--content {

            // form {
                
            //     input[type="submit"] {
            //         background: $secondary-colour-dark;
            //     }
            // }

            .submited-close-button {
                background: $secondary-colour-dark;

                &:hover {
                    box-shadow: 0 0.5em 0.5em -0.4em $secondary-colour-dark;
                }
            }
        }
    }

    .close-button {
        background: $secondary-colour-dark;
        // color: #FFF;

        &:hover {
            box-shadow: 0 0.5em 0.5em -0.4em $secondary-colour-dark;
        }
    }
}