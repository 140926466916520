@import '../../res/colours.scss';

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100%;
    background: $primary-colour;
    z-index: 3;
}

#dark {
    .main {
        background: $primary-colour-dark;
    }
}