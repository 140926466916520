@import '../../../res/colours.scss';

$social-link-colours:
    linear-gradient(red, yellow),
    #0072b1,
    #171515,
    #171515,
    #0077b5,
    #55acee,
    // #dd4b39, // Instagram
;

$anim-start-delay: 0.5s;

.app__social {
    @media screen and (max-width: 600px) {
        display: none;
    }

    // Ensures that it stays at the left of the screen.
    position: fixed;
    bottom: 0px;
    left: 1rem;
    z-index: 5;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;



    &-line {
        height: 70px;
    }

    // https://codepen.io/ephs23/pen/NeQZGx
    ul {
        // display: flex;
        // flex-direction: row;

        li {
            list-style: none;
            margin-bottom: 7px;
            // margin-right: 7px;

            a {
                position: relative;
                display: block;
                width: 35px;
                height: 35px;
                background: #fff;
                overflow: hidden;
                text-align: center;
                font-size: 30px;
                border-radius: 50%;
                border: 2px solid $secondary-colour;
                z-index: 1;

                .icon {
                    position: relative;
                    color: #262626;
                    transition: 0.5s ease-in-out;
                    z-index: 3;
                    padding: 0.35rem;
                }

                &:hover {
                    .icon {
                        color: #fff;
                    }

                    &:before {
                        top: 0;
                    }
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transition: 0.5s ease-in-out;
                    z-index: 2;
                }

                transform-origin: center;
                animation: ShowAll .3s ease-out;
                animation-fill-mode: both;
            }

            // a {
            //     &:before {
            //         background: attr([data-colour], color);
            //     }
            // }
            
            @for $i from 1 through length($social-link-colours) {
                &:nth-child(#{length($social-link-colours)}n+#{$i}) {
                    a {
                        animation-delay: $anim-start-delay + ($i * .15s);
                        &:before {
                            background: nth($social-link-colours, $i);
                        }
                    }
                }
            }
        }
    }
}


#dark {

    .app__social {
        ul {
            li {
                a {
                    border: 2px solid $secondary-colour-dark;
                }
            }
        }

    }
}




@keyframes ShowAll {
    0% {
        opacity: 0;
        transform: translate(-100px, 0);
        // transform: scale(.1);
    }

    80% {
        opacity: 0.2;
        // transform: scale(1.10);
    }

    100% {
        // transform: scale(1);
        transform: translate(0px, 0px);
        opacity: 1;
    }
}