@import '../../res/colours.scss';

.app__anim-message {
    display: flex;
    flex-direction: column;
    // width: 100%;
    // height: 80vh;
    justify-content: center;
    align-items: center;
    // background: red;
    // background: $primary-colour;
    

    @media screen and (max-width: 500px) {
        // width: 120%;
    }
}

#dark {
    .app__anim-message {
        // background: $primary-colour-dark;
    }
}