@import '../../../res/colours.scss';
@import '../../../utils/Animations/animations.scss';

.app__settings {
    @media screen and (max-width: 550px) {
        bottom: 15px;
        right: 15px;
    }
    // Ensures that it stays at the left of the screen.
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 5;

    &--container {
        @media screen and (max-width: 600px) {
            width: 70px;
            height: 40px;
        }
        display: flex;
        width: 80px;
        height: 50px;
        justify-content: center;
        align-items: center;
        background: rgba($black-colour, 0.2);
        border-radius: 20px;
        
        box-shadow: 0 0.5em 0.5em -0.4em $secondary-colour;
        transform: translateY(-0.25em);

        svg {
            width: 100%;
            height: 100%;
            color: white;

            padding: 0.3rem;
        }

        transform-origin: center;
        animation: slide-l-fade-In .3s ease-out;
        animation-fill-mode: both;
        animation-delay: 0.35s;
    }
}

#dark {

    .app__settings {
    
        &--container {
            box-shadow: 0 0.5em 0.5em -0.4em $secondary-colour-dark;
        }
    }
}