@import '../../../res/colours.scss';
@import '../../../constants/constants.scss';

:root {
    --drawer-menu-button-scale: 35px;
}

.app__drawer--menuBtn-container {
    position: absolute;
    display: inline-block;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    transform: translate(-15px, 15px);
    z-index: 10;


    button {
        display: flex;
        width: var(--drawer-menu-button-scale);
        height: var(--drawer-menu-button-scale);
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 50px;
        cursor: pointer;

        box-shadow: 0 5px 10px rgba(black, 0.5);

        transform-origin: center;
        animation: slide-l-fade-In .3s ease-out;
        animation-fill-mode: both;
        animation-delay: 0.35s;


        i {
            position: relative;
            display: inline-block;
            width: 18px;
            height: 3px;
            background: $black-colour;
            transition: .2s ease-out;

            &::before {
                top: -5px;
            }

            &::after {
                bottom: -5px;
            }

            &::before,
            &::after {
                content: '';
                width: 18px;
                height: 3px;
                background: black;
                position: absolute;
                left: 0;

                transition: .2s ease-out;
            }
        }

        &[data-menuvisible="true"] {
            i {
                // Hide the center line.
                background: transparent;

                &::before {
                    top: 0;
                    -webkit-transform: rotateZ(45deg);
                    -moz-transform: rotateZ(45deg);
                    -ms-transform: rotateZ(45deg);
                    -o-transform: rotateZ(45deg);
                    transform: rotateZ(45deg);
                }

                &::after {
                    bottom: 0;
                    -webkit-transform: rotateZ(-45deg);
                    -moz-transform: rotateZ(-45deg);
                    -ms-transform: rotateZ(-45deg);
                    -o-transform: rotateZ(-45deg);
                    transform: rotateZ(-45deg);
                }
            }
        }
    }
}