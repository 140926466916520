@import '../../../res/colours.scss';
@import '../../../utils/Animations/animations.scss';

main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 100vw;
    // height: 100vh;

    position: fixed;
    top: 0;
    left: 0;

    z-index: 10;


    .overlay {
        flex: 1;
        display: flex;
        background: rgba($black-colour, 0.85);
        width: 100vw;
        height: 100vh;

        z-index: 1;

        @include animation(fade-in);
        animation-duration: 0.3s;
        transition-timing-function: ease-out;
    }


    .container {
        @media screen and (max-width: 900px) {
        }
        @media screen and (max-width: 550px) {
            max-width: 95%;
        }
        position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        width: 80%;
        max-width: 600px;
        background: rgba(255, 255, 255, 0.75);
        // box-shadow: 0px 0px 18px 0px rgba($black-colour, 0.75);
        // border: 1px solid rgba(209, 213, 219, 0.3);
        border-radius: 12px;
        padding: 20px;


        display: flex;
        justify-content: center;
        align-items: center;
        

        z-index: 2;

        
        // backdrop-filter: blur(16px) saturate(180%);
        // -webkit-backdrop-filter: blur(16px) saturate(180%);

        
        @include animation(scale-fade-in);
        animation-duration: 0.3s;
        // animation-delay: 0s;
        transition-timing-function: ease-out;


        &--closeButton-container {
            position: absolute;
            top: 5px;
            right: 5px;
            padding: 10px;
            
            z-index: 3;

            button {
                display: flex;
                width: 35px;
                height: 35px;

                justify-content: center;
                align-items: center;
                border: none;
                border-radius: 50%;
                // border-width: 2px;
                cursor: pointer;


                // background: #e0e0e0;
                // box-shadow: 4px 4px 8px #bebebe,
                //     -4px -4px 8px #ffffff;

                // transition: var(--modeTransition);

                // &:hover {
                //     background: #e0e0e0;
                //     box-shadow: inset 4px 4px 8px #bebebe,
                //         inset -4px -4px 8px #ffffff;
                // }
            }
        }
    }
}