@import '../../res/colours.scss';
@import '../../utils/Animations/animations.scss';

.app__drawer {
    // Hide this menu when the screen is larger than 900px
    @media screen and (min-width: 900px) {
        display: none;
    }
    display: flex;
    z-index: 5;


    &--panel {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        // width: 80%;
        height: 100vh;
        z-index: 5;

        background: white;

        box-shadow: 0 0 20px rgb(128, 128, 128);

        div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 4.5rem 1.4rem;

            a {
                margin: 1rem;

                color: gray;
                text-decoration: none;
                font-size: 1rem;
                text-transform: uppercase;
                font-weight: 500;

                transition: 0.3s ease-in-out;

                // &:hover {
                //     color: $secondary-colour;
                // }
            }
        }
    }

    /*&--menuBtn-container {
        z-index: 10;

        button {
            display: flex;
            width: 35px;
            height: 35px;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 50px;
            cursor: pointer;

            box-shadow: 0 5px 10px rgba(black, 0.5);

            transform-origin: center;
            animation: slide-l-fade-In .3s ease-out;
            animation-fill-mode: both;
            animation-delay: 0.35s;


            i {
                position: relative;
                display: inline-block;
                width: 18px;
                height: 3px;
                background: $black-colour;
                transition: .2s ease-out;

                &::before {
                    top: -5px;
                }

                &::after {
                    bottom: -5px;
                }

                &::before,
                &::after {
                    content: '';
                    width: 18px;
                    height: 3px;
                    background: black;
                    position: absolute;
                    left: 0;

                    transition: .2s ease-out;
                }
            }

            &[data-menuvisible="true"] {
                i {
                    // Hide the center line.
                    background: transparent;

                    &::before {
                        top: 0;
                        -webkit-transform: rotateZ(45deg);
                        -moz-transform: rotateZ(45deg);
                        -ms-transform: rotateZ(45deg);
                        -o-transform: rotateZ(45deg);
                        transform: rotateZ(45deg);
                    }

                    &::after {
                        bottom: 0;
                        -webkit-transform: rotateZ(-45deg);
                        -moz-transform: rotateZ(-45deg);
                        -ms-transform: rotateZ(-45deg);
                        -o-transform: rotateZ(-45deg);
                        transform: rotateZ(-45deg);
                    }
                }
            }
        }
    }*/

    &--overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        opacity: 0;

        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.3);
        z-index: 2;
    }

    &--resume {
        position: absolute;
        bottom: 0px;
        left: 0px;
        // display: inline-block;
        padding: 0.3em 1.2em;
        margin: 0 0.3em 0.3em 0;
        box-sizing: border-box;
        color: #000000;
        text-align: center;
        text-decoration: none;
        border: 0.1em solid $gray-colour;
        border-radius: 20px;
        overflow: hidden;

        justify-content: flex-start;
        align-items: flex-start;
        margin: 1.8rem 2.4rem;

        transition: 0.2s ease-in;

        transform-origin: center;
        animation: slide-l-fade-In .3s 0.55s ease-out;
        animation-fill-mode: both;

        z-index: 10;


        &:hover {
            color: #FFFFFF;

            &:before {
                top: 0;
            }
        }

        &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 100%;
            transition: 0.5s ease-in-out;
            z-index: -2;
        }

        &:before {
            background: $secondary-colour;
        }
    }
}


/*#dark {

    .app__sidebar {

        &--panel {
            background: $primary-colour-dark;

            // box-shadow: 0 0 20px rgb(128, 128, 128);

            div {

                a {
                    color: $white-colour;
                    transition: 0.3s ease-in-out;

                    &:hover {
                        color: $secondary-colour-dark;
                    }
                }
            }
        }

        &--menuBtn-container {

            button {
                background: rgba($navbar-colour-dark, .85);
                box-shadow: 0px 5px 10px rgba($white-colour, 0.5);

                i {
                    background: $white-colour;

                    &::before,
                    &::after {
                        background: $white-colour;
                    }
                }

                &[data-menuVisible="false"] {

                }

                &[data-menuVisible="true"] {
                    i {
                        // Hide the center line.
                        background: transparent;
                    }
                }
            }
        }

        &--overlay {
            background: rgba(0, 0, 0, 0.7);
            // background: rgba(255, 255, 255, 0.3);
        }
    }
}*/